<template>
  <div>
    <Spinner />
    <!-- <b-spinner v-if="!oilExport" class="spinner" label="Large Spinner" /> -->
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="oilExport"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="oilExport"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(eta)="data">
          <p>
            {{ changeArr(data.item.from_date) }} -
            {{ changeArr(data.item.to_date) }}
          </p>
        </template>
        <template #cell(download)="data">
          <a class="fuel__export" :href="$url + '/api/web/export/download/' + data.item.path" target="_blank"> Скачать </a>
          <!--          <a class="fuel__export" @click="exportFuel(data.item.id)">Скачать</a>-->
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "@/components/spinner";

export default {
  components: { Spinner },
  data() {
    return {
      spinnerShow: true,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "title",
          label: "Имя",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "city",
          label: "Город",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "eta",
          label: "Дата",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "download",
          label: "Скачать",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
      ],
    };
  },
  computed: {
    rows() {
      if (this.oilExport) {
        this.$emit("spinner");
        return this.oilExport.length;
      }
      return 0;
    },
    ...mapState(["oilExport", "dateFilter"]),
  },
  async created() {
    await this.$store.dispatch("getOilExport");
  },
  methods: {
    changeArr(value) {
      if (value) {
        return value.split(" ")[0].split("-").join(".");
      }
      return value;
    },
    exportFuel(id) {
      if (this.dateFilter) {
        this.$api
          .get("/web/oil-export", {
            responseType: "blob",
            params: {
              order_id: id,
              from: this.dateFilter.start,
              to: this.dateFilter.end,
            },
          })
          .then((response) => {
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", id + ".xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
      }
    },
  },
};
</script>

<style scoped>
.pogination {
  position: absolute;
  top: -82px;
  right: 0;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.fuel__export:hover {
  text-decoration: underline;
}
</style>
